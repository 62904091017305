import { Box, Button, ButtonProps, Tooltip } from '@mui/material';
import { MoreVerticalIcon } from '@components/actionicons';
import { PopoverMenu, PopoverMenuLabelledItemT } from '@components/ng/popovermenu';

export type EmptyMode = 'none' | 'hidden';

export type ContextMenuProps<Key extends string> = {
  size?: 'small' | 'medium';
  disabled?: boolean;
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  options?: PopoverMenuLabelledItemT<Key>[];
  title?: string;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  onClick?: (key: Key) => void;
  autoClose?: boolean;
  emptyMode?: EmptyMode;
  children?: React.ReactNode;
};

export function ContextMenu<Key extends string>({
  title,
  placement = 'left',
  size = 'medium',
  variant = 'outlinedAlt',
  color = 'inherit',
  disabled,
  options,
  onClick,
  autoClose,
  emptyMode = 'none',
  children = <MoreVerticalIcon />,
}: ContextMenuProps<Key>) {
  if (!options || options.length === 0 || !onClick) {
    if (emptyMode === 'none') {
      return null;
    }

    return (
      <Box
        width="26px"
        height="26px"
      />
    );
  }

  return (
    <PopoverMenu<Key, PopoverMenuLabelledItemT<Key>>
      position="bottom_left"
      items={options}
      onMenuItemClick={onClick}
      autoClose={autoClose}
    >
      {({ open }) => (
        <Tooltip
          title={title}
          placement={placement}
          arrow
        >
          <span>
            <Button
              size={size === 'medium' ? 'mediumSquare' : 'smallSquare'}
              disabled={disabled}
              variant={variant}
              color={color}
              onClick={open}
            >
              {children}
            </Button>
          </span>
        </Tooltip>
      )}
    </PopoverMenu>
  );
}
