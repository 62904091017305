// Generouted, changes to this file will be overridden
/* eslint-disable */

import { components, hooks, utils } from "@generouted/react-router/client";

export type Path =
  | `/`
  | `/auth-failure`
  | `/auth-success`
  | `/dashboard/:zoneId?`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/airframes`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/airframes/:airframeId`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/allocation`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/allocation/:allocationId`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/baggagepersonaleffects`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/bodilyinjury`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/cargomail`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/claimants`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/costsexpenses`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/fees`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/findingsadvice`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/flights`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/flights/:flightId`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/indemnity`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/indemnity/aircrafthull`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/legalentities`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/lossoflicence`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/occurrence`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/personalaccident`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/personalinjury`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/property`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/report`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/salvage`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/sparesaircraftequipment`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/stakeholders`
  | `/dashboard/:zoneId?/adjustment/:adjustmentId/subrogation`
  | `/dashboard/:zoneId?/admin`
  | `/dashboard/:zoneId?/admin/adjustments`
  | `/dashboard/:zoneId?/admin/companies`
  | `/dashboard/:zoneId?/admin/groups`
  | `/dashboard/:zoneId?/admin/policies`
  | `/dashboard/:zoneId?/admin/roles`
  | `/dashboard/:zoneId?/admin/stakeholders`
  | `/dashboard/:zoneId?/admin/users`
  | `/dashboard/:zoneId?/insights`
  | `/dashboard/:zoneId?/insights/aviation`
  | `/dashboard/:zoneId?/insights/financial`
  | `/dashboard/:zoneId?/lossnotice/:lossNoticeId`
  | `/dashboard/:zoneId?/lossnotice/:lossNoticeId/airframes`
  | `/dashboard/:zoneId?/lossnotice/:lossNoticeId/airframes/:airframeId`
  | `/dashboard/:zoneId?/lossnotice/:lossNoticeId/filesdocuments`
  | `/dashboard/:zoneId?/lossnotice/:lossNoticeId/flights`
  | `/dashboard/:zoneId?/lossnotice/:lossNoticeId/flights/:flightId`
  | `/dashboard/:zoneId?/lossnotice/:lossNoticeId/occurrence`
  | `/dashboard/:zoneId?/lossnotice/:lossNoticeId/sparesaircraftequipment`
  | `/dashboard/:zoneId?/policy/:policyId`
  | `/dashboard/:zoneId?/policy/:policyId/market`
  | `/dashboard/:zoneId?/policy/:policyId/policydetails`
  | `/login`;

export type Params = {
  "/dashboard/:zoneId?": { zoneId?: string };
  "/dashboard/:zoneId?/adjustment/:adjustmentId": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/airframes": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/airframes/:airframeId": {
    zoneId?: string;
    adjustmentId: string;
    airframeId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/allocation": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/allocation/:allocationId": {
    zoneId?: string;
    adjustmentId: string;
    allocationId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/baggagepersonaleffects": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/bodilyinjury": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/cargomail": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/claimants": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/costsexpenses": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/fees": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/findingsadvice": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/flights": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/flights/:flightId": {
    zoneId?: string;
    adjustmentId: string;
    flightId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/indemnity": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/indemnity/aircrafthull": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/legalentities": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/lossoflicence": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/occurrence": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/personalaccident": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/personalinjury": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/property": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/report": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/salvage": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/sparesaircraftequipment": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/stakeholders": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/adjustment/:adjustmentId/subrogation": {
    zoneId?: string;
    adjustmentId: string;
  };
  "/dashboard/:zoneId?/admin": { zoneId?: string };
  "/dashboard/:zoneId?/admin/adjustments": { zoneId?: string };
  "/dashboard/:zoneId?/admin/companies": { zoneId?: string };
  "/dashboard/:zoneId?/admin/groups": { zoneId?: string };
  "/dashboard/:zoneId?/admin/policies": { zoneId?: string };
  "/dashboard/:zoneId?/admin/roles": { zoneId?: string };
  "/dashboard/:zoneId?/admin/stakeholders": { zoneId?: string };
  "/dashboard/:zoneId?/admin/users": { zoneId?: string };
  "/dashboard/:zoneId?/insights": { zoneId?: string };
  "/dashboard/:zoneId?/insights/aviation": { zoneId?: string };
  "/dashboard/:zoneId?/insights/financial": { zoneId?: string };
  "/dashboard/:zoneId?/lossnotice/:lossNoticeId": {
    zoneId?: string;
    lossNoticeId: string;
  };
  "/dashboard/:zoneId?/lossnotice/:lossNoticeId/airframes": {
    zoneId?: string;
    lossNoticeId: string;
  };
  "/dashboard/:zoneId?/lossnotice/:lossNoticeId/airframes/:airframeId": {
    zoneId?: string;
    lossNoticeId: string;
    airframeId: string;
  };
  "/dashboard/:zoneId?/lossnotice/:lossNoticeId/filesdocuments": {
    zoneId?: string;
    lossNoticeId: string;
  };
  "/dashboard/:zoneId?/lossnotice/:lossNoticeId/flights": {
    zoneId?: string;
    lossNoticeId: string;
  };
  "/dashboard/:zoneId?/lossnotice/:lossNoticeId/flights/:flightId": {
    zoneId?: string;
    lossNoticeId: string;
    flightId: string;
  };
  "/dashboard/:zoneId?/lossnotice/:lossNoticeId/occurrence": {
    zoneId?: string;
    lossNoticeId: string;
  };
  "/dashboard/:zoneId?/lossnotice/:lossNoticeId/sparesaircraftequipment": {
    zoneId?: string;
    lossNoticeId: string;
  };
  "/dashboard/:zoneId?/policy/:policyId": { zoneId?: string; policyId: string };
  "/dashboard/:zoneId?/policy/:policyId/market": {
    zoneId?: string;
    policyId: string;
  };
  "/dashboard/:zoneId?/policy/:policyId/policydetails": {
    zoneId?: string;
    policyId: string;
  };
};

export type ModalPath = never;

export const { Link, Navigate } = components<Path, Params>();
export const { useModals, useNavigate, useParams } = hooks<
  Path,
  Params,
  ModalPath
>();
export const { redirect } = utils<Path, Params>();
